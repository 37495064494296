import React from "react"
import { graphql } from "gatsby"

import Homepage from "../components/homepage/homepage"

const EsHomepage = props => <Homepage {...props} />

export const query = graphql`
  {
    sanityHomepage(_id: { eq: "homepage-es" }) {
      tabs {
        content {
          homeHeroSection {
            angle
            endColor
            startColor
            title
            subtitle
            isWhiteBackground
            links {
              buttonType
              label
              linkPosition
              newTab
              noFollow
              size
              type
              url
            }
          }
          _rawHomepageImageText(resolveReferences: { maxDepth: 10 })
          _rawPageBuilder(resolveReferences: { maxDepth: 10 })
          _rawPartnersCarousel(resolveReferences: { maxDepth: 10 })
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
  }
`

export default EsHomepage
